import { Injectable } from '@angular/core';
import { ApiResponseModel } from '@app/shared/api-response.model';
import { FeatureConstants } from '@app/shared/constants/feature.constants';
import { AppClient } from '@app/shared/constants/global-constant';
import { VenueDto } from '@app/venue/venue.dto';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { Venue } from './venue.entity';

@Injectable({
  providedIn: 'root'
})
export class VenueService extends BaseService {
  constructor() {
    super();
  }

  client = AppClient.isha;
  feature = FeatureConstants.Features.venue.name;

  private allVenues = new BehaviorSubject<ApiResponseModel<Venue[]>>({} as ApiResponseModel<Venue[]>);
  public allVenues$ = this.allVenues.asObservable();

  loadAllVenues(useCache = true): void {
    if (this.hasLoadedFromCache(this.allVenues, useCache)) {
      return;
    }

    this.get<Venue[]>(this.endpoint(true), {
      action: FeatureConstants.Features.venue.actions.getAll
    }).subscribe(
      venues => {
        this.allVenues.next(venues);
      },
      error => console.log(`Error: ${error}`)
    );
  }

  saveVenue(venue: VenueDto): Observable<ApiResponseModel<Venue>> {
    const action = venue?.venueId
      ? FeatureConstants.Features.venue.actions.update
      : FeatureConstants.Features.venue.actions.create;
    return this.post(this.endpoint(), { action, ...venue });
  }

  clearGroupCache(): void {
    this.clearCache(this.allVenues);
  }
}
