<h2 mat-dialog-title class="title">Add Registration</h2>
<div class="form-container" [formGroup]="parent" fxLayout="column" fxLayoutGap="0">
  <mat-form-field>
    <mat-label>First Name</mat-label>
    <input matInput formControlName="firstName" required />
    <mat-icon color="accent" *ngIf="parent?.get('firstName')?.valid" matSuffix>done</mat-icon>
    <mat-error *ngIf="parent?.get('firstName')?.hasError('required')">please enter first name.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Last Name</mat-label>
    <input matInput formControlName="lastName" required />
    <mat-icon color="accent" *ngIf="parent?.get('lastName')?.valid" matSuffix>done</mat-icon>
    <mat-error *ngIf="parent?.get('lastName')?.hasError('required')">please enter last name.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput formControlName="email" required />
    <mat-icon color="accent" *ngIf="parent?.get('email')?.valid" matSuffix>done</mat-icon>
    <mat-error *ngIf="parent?.get('email')?.hasError('required')">please enter an email.</mat-error>
    <mat-error *ngIf="parent?.get('email')?.hasError('email')">please enter a valid email address.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Gender</mat-label>
    <input matInput formControlName="gender" required />
    <mat-icon color="accent" *ngIf="parent?.get('gender')?.valid" matSuffix>done</mat-icon>
    <mat-error *ngIf="parent?.get('gender')?.hasError('required')">please enter gender.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Mobile Phone</mat-label>
    <input matInput formControlName="mobilePhone" required />
    <mat-icon color="accent" *ngIf="parent?.get('mobilePhone')?.valid" matSuffix>done</mat-icon>
    <mat-error *ngIf="parent?.get('mobilePhone')?.hasError('required')">please enter mobile phone.</mat-error>
    <mat-error
      *ngIf="!parent?.get('mobilePhone')?.hasError('required') && parent?.get('mobilePhone')?.hasError('wrongNumber')"
    >
      {{ parent?.get('mobilePhone')?.getError('wrongNumber') }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Payment Ref</mat-label>
    <input matInput formControlName="paymentReference" />
  </mat-form-field>
</div>
