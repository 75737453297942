<h2>Health Declaration</h2>
<hr>
<div *ngIf="program$ | async as p">
  <h3>{{p.shortName}}</h3>
</div>
<div *ngIf="dataSource.data" class="table-container mat-elevation-z8" tabindex="0">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef> First Name </th>
      <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> Last Name </th>
      <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="mobilePhone">
      <th mat-header-cell *matHeaderCellDef> Mobile Phone </th>
      <td mat-cell *matCellDef="let element"> {{element.mobilePhone}} </td>
    </ng-container>

    <ng-container matColumnDef="age">
      <th mat-header-cell *matHeaderCellDef> Age </th>
      <td mat-cell *matCellDef="let element"> {{element.age}} </td>
    </ng-container>

    <ng-container matColumnDef="physicalAilments">
      <th mat-header-cell *matHeaderCellDef> Physical Ailments </th>
      <td mat-cell *matCellDef="let element"> {{element.physicalAilments}} </td>
    </ng-container>

    <ng-container matColumnDef="mentalAilments">
      <th mat-header-cell *matHeaderCellDef> Mental Ailments </th>
      <td mat-cell *matCellDef="let element"> {{element.mentalAilments}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
<!--    <tr class="mat-row" *matNoDataRow>-->
<!--      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>-->
<!--    </tr>-->
  </table>
</div>

