import { Injectable } from '@angular/core';
import { DateConstants } from '@app/shared/services/date/date-constants';
import { DateService } from '@app/shared/services/date/date.service';
import { LoginUser, SelectedCenter } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class CurrentGroupService {
  SELECTED_CENTER = 'selected_center';

  constructor(private date: DateService) {}

  setCurrentGroup(user: LoginUser, group: string): void {
    if (user) {
      const expiresAt = this.date.formatDate(this.date.addDays(30), DateConstants.ClientFormat);
      const center: SelectedCenter = { value: group, expiresAt };

      localStorage.setItem(this.SELECTED_CENTER, JSON.stringify(center));
    }
  }

  getCurrentGroup(user: LoginUser): string {
    if (!user) { return ''; }

    let groupName = Object.keys(user.permissions)[0];
    const storage = localStorage.getItem(this.SELECTED_CENTER);

    if (storage) {
      const center = JSON.parse(storage) as SelectedCenter;
      const expiresAt = center.expiresAt;
      const now = this.date.formatDate(this.date.getToday(), DateConstants.ClientFormat);
      if (!this.date.isSameDayOrBefore(expiresAt, now)) {
        groupName = center.value;
      }
    }

    return groupName;
  }
}
