<section>
  <div fxFlex="20%" fxHide.lt-sm></div>
  <div fxFlex="grow" fxLayout="column">
    <h2 fxLayoutAlign="center center">Login using Your Social Account</h2>
    <mat-card>
      <mat-card-content>
        <div fxFlex></div>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20px grid">
          <form (ngSubmit)="login($event, 'google')" [action]='googleLoginLink' method="post">
            <input hidden name="onSuccessUrl" [(ngModel)]="onSuccessUrl">
            <button type="submit" mat-raised-button color="google" [ladda]="loadingStatus['google']">
              <fa-icon [icon]=faGoogle></fa-icon>
              Google
            </button>
          </form>
<!--          <form (ngSubmit)="login($event, 'facebook')" [action]='facebookLoginLink' method="post">-->
<!--            <input hidden name="onSuccessUrl" [(ngModel)]="onSuccessUrl">-->
<!--            <button type="submit" mat-raised-button color="facebook" [ladda]="loadingStatus['facebook']">-->
<!--              <fa-icon [icon]=faFacebookF></fa-icon>-->
<!--              Facebook-->
<!--            </button>-->
<!--          </form>-->
          <form (ngSubmit)="login($event, 'microsoft')" [action]='microsoftLoginLink' method="post">
            <input hidden name="onSuccessUrl" [(ngModel)]="onSuccessUrl">
            <button type="submit" mat-raised-button color="microsoft" [ladda]="loadingStatus['microsoft']">
              <fa-icon [icon]=faMicrosoft></fa-icon>
              Microsoft
            </button>
          </form>
          <form (ngSubmit)="login($event, 'yahoo')" [action]='yahooLoginLink' method="post">
            <input hidden name="onSuccessUrl" [(ngModel)]="onSuccessUrl">
            <button type="submit" mat-raised-button color="yahoo" [ladda]="loadingStatus['yahoo']">
              <fa-icon [icon]=faYahoo></fa-icon>
              Yahoo
            </button>
          </form>
        </div>
        <div fxFlex></div>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex="20%" fxHide.lt-sm></div>

</section>
