import { Injectable } from '@angular/core';
import { ApiResponseModel } from '@app/shared/api-response.model';
import { FeatureConstants } from '@app/shared/constants/feature.constants';
import { AppClient } from '@app/shared/constants/global-constant';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from '../base.service';
import { VolunteerEntity } from './volunteer.entity';

@Injectable({
  providedIn: 'root'
})
export class VolunteerReportService extends BaseService {
  client = AppClient.isha;
  feature = FeatureConstants.Features.volunteer.name;

  constructor() {
    super();
  }

  private siaVolsReport = new BehaviorSubject<ApiResponseModel<VolunteerEntity[]>>(
    {} as ApiResponseModel<VolunteerEntity[]>
  );
  public siaVolsReport$ = this.siaVolsReport.asObservable();

  loadVolunteerReport(programId: string): void {
    this.get<VolunteerEntity[]>(this.endpoint(), {
      action: FeatureConstants.Features.volunteer.actions.query,
      programId: programId
    }).subscribe(
      volsEntity => {
        this.siaVolsReport.next(volsEntity);
      },
      error => console.log(`Error: ${error}`)
    );
  }
}
