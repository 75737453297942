import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { finalize, map, scan } from 'rxjs/operators';
import { ApiResponseModel } from './api-response.model';
import { getSessionKey } from './utils/local-storage-util';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  constructor(private http: HttpClient) {
    // this.url = `http://localhost:8080`;
  }

  url = environment.backendUrl; // `http://localhost:8080`; // `https://crmt.ishayoga.live`;
  useLive = false;

  loadingChange$ = (new Subject<boolean>()).pipe(
    scan((state, isLoading) => {
      if(isLoading) state = state + 1;
      else if(state === 0) return 0; //state can't be less than zero
      else state = state - 1;
      return state;
    }, 0),
    map(state => {
      return state > 0;
    })
  ) as Subject<boolean>;

  api(endpoint: string, values: any, auth = true) {
    const cmds = endpoint.split('.');
    const params: Record<string, any> = {};
    params.action = cmds[1];

    if (auth === undefined) {
      auth = true;
    }

    const path = auth ? '_api/' : '';

    _.forEach(values, (v, k) => {
      if (v === '_today_') {
        params[k] = moment().format('YYYYMMDD');
      } else {
        params[k] = (values as any)[k];
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Session ${getSessionKey()}`
      }),
      // withCredentials: true
    };

    this.loadingChange$.next(true);
    return this.http
      .post<ApiResponseModel<any>>(`${this.url}/${path}${cmds[0]}`, new HttpParams({ fromObject: params }), httpOptions)
      .pipe(
        finalize(() => {
          this.loadingChange$.next(false);
        })
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get<T>(endpoint: string, payload: any): Observable<ApiResponseModel<T>> {
    return this.post(endpoint, payload);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T>(endpoint: string, payload: any): Observable<ApiResponseModel<T>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Session ${getSessionKey()}`
      }),
      // withCredentials: true
    };

    const params = Object.keys(payload)
      .map(k => this.getPayloadProp(payload, k))
      .filter(Boolean)
      .join('&');

    this.loadingChange$.next(true);
    const endpointUrl = endpoint?.startsWith("http") ? endpoint :`${this.url}/${endpoint}`;
    return this.http.post<ApiResponseModel<T>>(endpointUrl, params, httpOptions).pipe(
      finalize(() => {
        this.loadingChange$.next(false);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getPayloadProp(payload: any, k: string): string {
    const paramVal = payload?.[k];

    if (Array.isArray(paramVal)) {
      return this.getArrayPayloadProp(k, paramVal);
    }

    return this.getSimplePayloadProp(k, paramVal);
  }

  private getSimplePayloadProp(key: string, value: string): string {
    if (!value) {
      return undefined;
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getArrayPayloadProp(key: string, value: any[]): string {
    return value.map(val => this.getSimplePayloadProp(key, val)).join('&');
  }

  getCountry() {
    this.loadingChange$.next(true);
    return this.http
      .get('https://asia-east2-ishacrmserver.cloudfunctions.net/geo-redirect/getCountry', { responseType: 'text' })
      .pipe(
        finalize(() => {
          this.loadingChange$.next(false);
        })
      );
  }

  gformSubmit(scriptId: string, params: any) {
    const formUrl = 'https://script.google.com/macros/s/' + scriptId + '/exec';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    this.loadingChange$.next(true);
    return this.http.post(formUrl, new HttpParams({ fromObject: params }), httpOptions).pipe(
      finalize(() => {
        this.loadingChange$.next(false);
      })
    );
  }
}
