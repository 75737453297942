import { Component, OnInit } from '@angular/core';
import { PrsBackendService } from '@app/shared/services/prs-backend.service';
import { ActivatedRoute } from '@angular/router';
import { ProgramService } from '@app/shared/services/program/program.service';
import { filter, map } from 'rxjs/operators';
import { Program } from '@app/shared/services/program/program.entity';
import { ProgramInfoDto } from '@app/program-info/program-info.dto';
import { MatTableDataSource } from '@angular/material/table';

export interface HealthInfo {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  age: string;
  physicalAilments: string;
  mentalAilments: string;
}

@Component({
  selector: 'app-health-info',
  templateUrl: './health-info.component.html',
  styleUrls: ['./health-info.component.scss']
})
export class HealthInfoComponent implements OnInit {
  programId: string;
  program$ = this.programService.program$.pipe(
    filter(obj => obj.object && Object.keys(obj.object).length > 0),
    map(p => this.mapToDto(p.object))
  );
  displayedColumns: string[] = ['id', 'firstName', 'lastName', 'age', 'physicalAilments', 'mentalAilments', 'email', 'mobilePhone'];
  dataSource = new MatTableDataSource<HealthInfo>();

  constructor(private prs: PrsBackendService,
              private route: ActivatedRoute,
              private programService: ProgramService) {
  }

  ngOnInit(): void {
    this.programId = this.route.snapshot.paramMap.get('programId');
    this.programService.loadProgramDetails(this.programId);

    this.prs.get<HealthInfo[]>(`registration/program/${this.programId}/health`).subscribe(
      data => {
        const fd: HealthInfo[] = [];
        data.forEach(hi => {
          if (hi.physicalAilments !== 'N/A' || hi.mentalAilments !== 'N/A') {
            fd.push(hi);
          }
        });

        this.dataSource.data = fd;
      },
      error => {
        console.log(`Error: ${error}`);
      }
    );
  }

  mapToDto(program: Program): ProgramInfoDto {
    return {
      shortName: program?.shortName,
      startYYYYMMDD: program?.startYYYYMMDD,
      programId: program?.programId,
      fee: program?.fee
    } as ProgramInfoDto;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
