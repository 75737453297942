import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPrintModule } from 'ngx-print';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './auth/login/login.module';
import { CoreModule } from './core/core.module';
import { MailStatsComponent } from './mail-content/mail-stats/mail-stats.component';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './nav/header/header.component';
import { ProgramInfoModule } from './program-info/program-info.module';
import { AppInjector } from './shared/app-injector';
import { NotificationComponent } from './shared/components/notification/notification.component';
// import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as tz from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';


dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(customParseFormat);

@NgModule({
  declarations: [AppComponent, HeaderComponent, NotificationComponent, MailStatsComponent],
  imports: [
    BrowserAnimationsModule,
    FlexLayoutModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    LoginModule,
    CoreModule,
    ProgramInfoModule,
    NgxPrintModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary, injector: Injector) {
    // Add an icon to the library for convenient access in other components
    // library.addIcons(faFacebookF, faGoogle);
    AppInjector.injector = injector;
  }
}
