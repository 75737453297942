import { DefaultColumnDefFilterParam } from '@app/shared/constants/global-constant';
import { ColumnDef } from '@app/shared/entities/column-def.entity';
import { ProgInfoMemberDto } from './program-member.dto';

export const columnDefs: Array<ColumnDef<ProgInfoMemberDto>> = [
  {
    headerName: 'Member ID',
    field: 'memberId',
    filterParams: DefaultColumnDefFilterParam
  },
  {
    headerName: 'First Name',
    field: 'firstName',
    filterParams: DefaultColumnDefFilterParam
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    filterParams: DefaultColumnDefFilterParam
  },
  {
    headerName: 'Email',
    field: 'email',
    filterParams: DefaultColumnDefFilterParam
  },
  {
    headerName: 'Mobile Phone',
    field: 'mobilePhone',
    filterParams: DefaultColumnDefFilterParam
  },
  {
    headerName: 'Home Phone',
    field: 'homePhone',
    filterParams: DefaultColumnDefFilterParam
  },
  {
    headerName: 'Country',
    field: 'country',
    filterParams: DefaultColumnDefFilterParam
  }
];

export const RegistrationStatus = {
  RegistrationComplete: 'REGISTRATION_COMPLETE',
  Cancelled: 'CANCELLED'
};
