import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailStats } from '@app/shared/entities/mail-stats.entity';
import { MailContentService } from '@app/shared/services/mail-content/mail-content.service';
import { map } from 'rxjs/operators';
import { MailDto } from './../mail-content.dto';
import { MailStatsDto } from './mail-stats.dto';

@Component({
  selector: 'app-mail-stats',
  templateUrl: './mail-stats.component.html',
  styleUrls: ['./mail-stats.component.scss']
})
export class MailStatsComponent implements OnInit {
  constructor(
    private mailContentService: MailContentService,
    public dialogRef: MatDialogRef<MailStatsComponent>,
    @Inject(MAT_DIALOG_DATA) public mail: MailDto
  ) {}

  isLoading$ = this.mailContentService.loadingChange$;

  mailStatsData$ = this.mailContentService.mailStat$.pipe(map(p => this.mapToMailStatsDto(p?.object)));

  ngOnInit(): void {
    this.mailContentService.loadStat(this.mail.mailContentId);
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  mapToMailStatsDto(mailStats: MailStats): MailStatsDto {
    if (mailStats == null) {
      return {} as MailStatsDto;
    }

    const countryWiseClicks = Object.keys(mailStats.countryWiseClicks).map(key => ({
      name: key,
      clicks: mailStats.countryWiseClicks[key]
    }));

    const urlWiseClicks = Object.keys(mailStats.urlWiseClicks).map(key => ({
      shortened: key.replace('https://', '').replace('http://', '').replace('www.', ''),
      value: key,
      clicks: mailStats.urlWiseClicks[key]
    }));

    return {
      delivered: mailStats.delivered,
      sent: mailStats.sent,
      opens: mailStats.opens,
      openRate: (mailStats.opens * 100) / mailStats.delivered || 0,
      rejects: mailStats.rejects,
      defers: mailStats.defers,
      spamComplaints: mailStats.spamComplaints,
      bounces: mailStats.bounces,
      clicks: mailStats.clicks,
      clickRate: (mailStats.clicks * 100) / mailStats.delivered || 0,
      countryWiseClicks,
      urlWiseClicks
    } as MailStatsDto;
  }
}
