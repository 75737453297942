import { Injectable } from '@angular/core';
import { ApiResponseModel } from '@app/shared/api-response.model';
import { FeatureConstants } from '@app/shared/constants/feature.constants';
import { AppClient } from '@app/shared/constants/global-constant';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { Registration, RegistrationNewDto, RegistrationSummary } from './registration.entity';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends BaseService {
  constructor() {
    super();
  }

  client = AppClient.isha;
  feature = FeatureConstants.Features.register.name;

  private registrationSummary = new BehaviorSubject<{
    [programId: string]: ApiResponseModel<RegistrationSummary[]>;
  }>({});
  public registrationSummary$ = function (programId: string): Observable<ApiResponseModel<RegistrationSummary[]>> {
    return this.registrationSummary
      .pipe(
        filter(d => d[programId]),
        map(d => d[programId])
      )
      .asObservable();
  };

  getRegistrationInfo(registrationId: string): Observable<ApiResponseModel<Registration>> {
    return this.get<Registration>(this.endpoint(), {
      action: FeatureConstants.Features.register.actions.get,
      registrationId
    });
  }

  getRegistrationDetails(registrationId: string): Observable<ApiResponseModel<Registration>> {
    return this.get<Registration>(this.endpoint(), {
      action: FeatureConstants.Features.register.actions.get,
      registrationId
    });
  }

  register(registration: RegistrationNewDto): Observable<ApiResponseModel<Registration>> {
    return this.post(this.endpoint(), {
      action: FeatureConstants.Features.register.actions.registerForProgram,
      ...registration
    });
  }

  loadRegistrationSummary(programId: string, getStats = true): void {
    if (this.registrationSummary.getValue()[programId]?.statusCode === 'SUCCESS') {
      return;
    }
    this.get<RegistrationSummary[]>(this.endpoint(), {
      action: FeatureConstants.Features.register.actions.getSummary,
      programId,
      getStats
    }).subscribe(rs => {
      // creates a new object but keeps the same Observables
      const value = { ...this.registrationSummary.getValue() };
      value[programId] = rs;
      this.registrationSummary.next(value);
    });
  }

  queryRegistration(
    programId: string,
    updateCheckinStatus: boolean,
    updateFormStatus: boolean
  ): Observable<ApiResponseModel<Registration[]>> {
    return this.get<Registration[]>(this.endpoint(), {
      action: FeatureConstants.Features.register.actions.query,
      programId,
      updateCheckinStatus,
      updateFormStatus
    });
  }

  queryRegistrationByMember(
    memberId: string,
    updateCheckinStatus: boolean,
    simpleQuery: boolean,
    updateProgramProp: boolean
  ): Observable<ApiResponseModel<Registration[]>> {
    return this.get<Registration[]>(this.endpoint(), {
      action: FeatureConstants.Features.register.actions.query,
      memberId,
      updateCheckinStatus,
      simpleQuery,
      updateProgramProp
    });
  }
}
