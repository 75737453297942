import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RegistrationNewDto } from '@app/shared/services/registration/registration.entity';
import { FormGroupDto } from '@app/shared/types/form-group-dto.type';

@Component({
  selector: 'app-add-registration-form',
  templateUrl: './add-registration-form.component.html',
  styleUrls: ['./add-registration-form.component.scss']
})
export class AddRegistrationFormComponent implements OnInit {
  @Input()
  parent: FormGroup<FormGroupDto<RegistrationNewDto>>;

  constructor() {}

  ngOnInit(): void {}
}
