import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegistrationFormsDialogData } from '@app/program-info/program-info.dto';

@Component({
  selector: 'app-registration-forms-dialog',
  templateUrl: './registration-forms-dialog.component.html',
  styleUrls: ['./registration-forms-dialog.component.scss']
})
export class RegistrationFormsDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RegistrationFormsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RegistrationFormsDialogData
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close();
  }
}
