import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { NotificationService } from '../components/notification/notification.service';
import { getSessionKey } from '../utils/local-storage-util';

@Injectable({
  providedIn: 'root'
})
export class PrsBackendService {
  url = environment.backendUrlPrs;

  loadingChange$ = new Subject<boolean>();

  constructor(private http: HttpClient, private ntf: NotificationService) {
  }

  get<T>(endpoint: string, isAuthEnabled = true): Observable<T> {
    this.loadingChange$.next(true);

    return this.http.get<T>(`${this.url}${this.getPrefix(isAuthEnabled)}${endpoint}`, this.httpOptions())
      .pipe(
        catchError(this.handleError.bind(this)),
        finalize(() => {
          this.loadingChange$.next(false);
        })
      );
  }

  stream(endpoint: string): Observable<HttpResponse<Blob>> {
    this.loadingChange$.next(true);

    return this.http.get(endpoint, { responseType: 'blob', observe: 'response' })
      .pipe(
        catchError(this.handleError.bind(this)),
        finalize(() => {
          this.loadingChange$.next(false);
        })
      );
  }

  post<T, R>(endpoint: string, body: T, isAuthEnabled = true): Observable<R> {
    this.loadingChange$.next(true);

    return this.http.post<R>(`${this.url}${this.getPrefix(isAuthEnabled)}${endpoint}`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError.bind(this)),
        finalize(() => {
          this.loadingChange$.next(false);
        })
      );
  }

  put<T, R>(endpoint: string, body: T, isAuthEnabled = true): Observable<R> {
    this.loadingChange$.next(true);

    return this.http.put<R>(`${this.url}${this.getPrefix(isAuthEnabled)}${endpoint}`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError.bind(this)),
        finalize(() => {
          this.loadingChange$.next(false);
        }),
      );
  }

  delete<R>(endpoint: string, isAuthEnabled = true): Observable<R> {
    this.loadingChange$.next(true);

    return this.http.delete<R>(`${this.url}${this.getPrefix(isAuthEnabled)}${endpoint}`, this.httpOptions())
      .pipe(
        catchError(this.handleError.bind(this)),
        finalize(() => {
          this.loadingChange$.next(false);
        }),
      );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let err;

    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      err = `An error occurred: ${error?.error}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      err = `${error?.error?.error || error?.error?.detail || JSON.stringify(error?.error)}`;
    }

    this.ntf.error(err);
    return throwError(err);
  }

  private getPrefix(isAuthEnabled: boolean): string {
    return isAuthEnabled ? '/_api/' : '/';
  }

  private httpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Session ${getSessionKey()}`
      })
    };
  }
}
