<button
  mat-flat-button
  color="accent"
  [disabled]="!this.gridApi?.getDisplayedRowCount() || (isSubmitting$ | async)"
  (click)="exportAsCSV()"
>
  <span class="icon-fa-download">
    <i class="fas fa-download"></i>
  </span>
  Export CSV
</button>
