<h1 mat-dialog-title class="text-center"><b>Mail Stats</b></h1>
<div *ngIf="(isLoading$ | async) === false; else loading">
  <div mat-dialog-content *ngIf="mailStatsData$ | async; let stats" fxLayout="column">
    <div fxLayout="row" class="mt-2 mb-1" fxLayout.xs="column">
      <div fxLayout="column" fxFlex="50%" fxFlex.xs="100%" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutGap="10px">
          <strong fxFlex="50%" class="text-right" fxLayoutGap="10px">Sent:</strong>
          <strong fxFlex="50%">{{ stats.delivered }} / {{ stats.sent }}</strong>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <strong fxFlex="50%" class="text-right">Opened:</strong>
          <strong fxFlex="50%">
            {{ stats.opens }}<span> ({{ stats.openRate | number: '1.2-2' }}%)</span>
          </strong>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxFlex="50%" class="text-right">Rejected:</div>
          <div fxFlex="50%">{{ stats.rejects }}</div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxFlex="50%" class="text-right">Defers:</div>
          <div fxFlex="50%">{{ stats.defers }}</div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxFlex="50%" class="text-right">Spam:</div>
          <div fxFlex="50%">{{ stats.spamComplaints }}</div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" class="mb-1">
          <div fxFlex="50%" class="text-right">Bounces:</div>
          <div fxFlex="50%">{{ stats.bounces }}</div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50%" fxFlex.xs="100%" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutGap="10px">
          <strong fxFlex="50%" class="text-right">Clicks:</strong>
          <strong fxFlex="50%">
            {{ stats.clicks }}<span> ({{ stats.clickRate | number: '1.2-2' }}%)</span>
          </strong>
        </div>
        <div *ngFor="let country of stats?.countryWiseClicks" fxLayout="row" fxLayoutGap="10px">
          <div fxFlex="50%" class="text-right">{{ country.name }}:</div>
          <div fxFlex="50%">{{ country.clicks }}</div>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="mt-2">
      <strong class="url-header" fxLayoutAlign="center">URL Clicks</strong>
      <div class="url-row" *ngFor="let url of stats?.urlWiseClicks" fxLayout="row" fxLayoutGap="10px">
        <a target="_blank" href="{{ url.value }}" class="url-link" fxFlex="95%">
          {{ url.shortened }}
        </a>
        <div fxFlex="5%" class="url-clicks">{{ url.clicks }}</div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <p>Loading...</p>
</ng-template>
<div mat-dialog-actions>
  <button mat-button (click)="onCloseClick()" class="close-button">Close</button>
</div>
