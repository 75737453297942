import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppRouteService {
  constructor() {}

  getMemberProfileUrl(memberId: string): string {
    return `/profile/contact?memberId=${memberId}`;
  }
}
