<div class="add-reg-container">
  <app-add-registration-form [parent]="registrationFormGroup"> </app-add-registration-form>
  <div class="button-container">
    <button
      mat-raised-button
      color="accent"
      (click)="submitRegistration()"
      class="submit-button"
      [disabled]="!registrationFormGroup.valid"
    >
      Submit
    </button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</div>
