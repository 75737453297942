import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonEditorSchemaService {
  private readonly schema = {
         "$schema": "http://json-schema.org/draft-07/schema#",
        "type": "object",
        "properties": {
          "ages": {
            "type": "object",
            "properties": {
              "min": { "type": "number" },
              "max": { "type": "number" }
            },
            "additionalProperties" : false 
          },
          "ccy": { "type": "string" },
          "closed": { "type": "boolean" },
          "support": {
            "type": "object",
            "properties": {
              "email": { "type": "string" },
              "phone": { "type": "array", "items": { "type": "string" } }
            },
            "additionalProperties": false
          },
          "physicalAilment": {
            "type": "object",
            "properties": {
              "description": { "type": "string" }
            },
            "additionalProperties": false
          },
          "mentalAilment": {
            "type": "object",
            "properties": {
              "description": { "type": "string" }
            },
            "additionalProperties": false
          },
          "display": {
            "type": "object",
            "properties": {
              "ackNotes": { "type": "boolean" },
              "sessionsMandatory": { "type": "boolean" },
              "subtitle": { "type": "boolean" },
              "pregnancy": { "type": "boolean" },
              "ishaPrograms": { "type": "boolean" },
              "otherPrograms": { "type": "boolean" },
              "foodAllergies": { "type": "boolean" },
              "prevProgram": { "type": "boolean" }
            },
            "additionalProperties": false
          },
          "notes": {
            "type": "array",
            "items": { "type": "string" }
          },
          "prerequisites": {
            "type": "array",
            "items": { "type": "string" }
          },
          "programInfoLink": { "type": "string" },
          "prerequisitePrograms": {
            "type": "array",
            "items": {
              "type": "object",
              "properties": {
                "program": { "type": "string" },
                "example": { "type": "string" }
              },
              "additionalProperties": false
            }
          },
          "refProgramId": { "type": "integer" },
          "refundPolicy": { "type": "string" },
          "schedule": {
            "type": "array",
            "items": { "type": "string" }
          },
          "subtitle": { "type": "string" },
          "tnc": {
            "type": "array",
            "items": { "type": "string" }
          },
          "title": { "type": "string" },
          "venue": {
            "type": "object",
            "properties": {
              "mapLink": { "type": "string" },
              "displayName": { "type": "string" },
              "address": { "type": "string" }
            },
            "additionalProperties": false
          },
          "addressFields": {
            "type": "object",
            "properties": {
              "postalCode": { "type": "boolean" },
              "city": { "type": "boolean" },
              "states": {
                "type": "array",
                "items": { "type": "string" }
              }
            },
            "additionalProperties": false
          },
          "questions": {
            "type": "array",
            "items": {
              "type": "object",
              "properties": {
                "title": { "type": "string" },
                "id": { "type": "string" },
                "description": { "type": "string" },
                "type": { "type": "string", "enum": ["BOOL", "TEXT", "TEXTAREA"] },
                "labels": {
                  "type": "object",
                  "additionalProperties": { "type": "string" }
                },
                "postSection": { "type": "boolean" }
              },
              "required": ["title", "id", "type"],
              "additionalProperties": false
            }
          },
          "residential": { "type": "boolean" },
          "featured": { "type": "boolean" }
        },
        "additionalProperties": false
      };

  getSchema() {
    return this.schema;
  }
}
