<div fxLayout="column" fxLayoutGap="16px">
  <hr />
  <div fxLayout="row" *ngIf="isProgramInLastOneWeek && numCompleted">Registrations: {{ numCompleted }}</div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="5px">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="5px">
      <button mat-flat-button color="accent" (click)="showAddRegistrationModal()">
        <span class="icon-fa-plus">
          <i class="fas fa-plus"></i>
        </span>
        Add Registration
      </button>
    </div>
    <div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutGap="5px">
        <button mat-flat-button color="accent"
                *ngIf="canViewHealthInfo"
                [routerLink]="healthInfoLink">
            <span class="icon-fa-book-medical">
              <i class="fas fa-book-medical"></i>
            </span>
          Health Info
        </button>
        <button
          mat-flat-button
          color="accent"
          (click)="showCancelledRegistrationModal()"
          *ngIf="cancellationsCount > 0"
        >
          <span class="icon-fa-plus">
            <i class="fas fa-user-times"></i>
          </span>
          Cancellations ({{ cancellationsCount }})
        </button>
        <button
          mat-flat-button
          color="accent"
          (click)="showPendingRegistrationModal()"
          *ngIf="pendingRegistrationsCount > 0"
        >
          <span class="icon-fa-plus">
            <i class="fas fa-shopping-basket"></i>
          </span>
          Pending registrations ({{ pendingRegistrationsCount }})
        </button>
        <app-export-csv-button [gridApi]="gridApi" [csvExportParams]="csvExportParams"> </app-export-csv-button>
      </div>
    </div>
  </div>
  <!--
  For stage 2
    <app-print-forms-button
    [registrations]="registrations$ | async"
    [program]="program"
  ></app-print-forms-button>
 -->
  <div class="grid-parent-div" fxLayout="row">
    <ag-grid-angular
      [rowData]="rowData"
      [gridOptions]="gridOptions"
      class="ag-theme-balham"
      [pagination]="true"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
  <div class="vertical-spacer"></div>
</div>
