import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { VenueService } from '@app/shared/services/venue/venue.service';
import { Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { BackendService } from '../../shared/backend.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  destroy$ = new Subject();
  loading$ = this.backendService.loadingChange$;
  user$ = this.authService.user$;

  currentSelectedGroup$ = this.authService.currentSelectedGroup$;
  userGroups$ = combineLatest([this.user$, this.currentSelectedGroup$]).pipe(
    map(([user, currentGroup]) =>
      Object.keys(user.permissions).filter(group => group !== currentGroup)
    )
  );

  constructor(
    private authService: AuthService,
    private backendService: BackendService,
    private router: Router,
    private venueService: VenueService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  logout(): void {
    this.authService.logout();
  }

  setGroup(group: string): void {
    if (group) {
      this.authService.setGroup(group);
      this.venueService.clearGroupCache();
      this.router.navigate(['']);
    }
  }
}
