import { Component, Input } from '@angular/core';
import { CsvExportParams, GridApi } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-export-csv-button',
  templateUrl: './export-csv-button.component.html',
  styleUrls: ['./export-csv-button.component.scss']
})
export class ExportCsvButtonComponent extends BaseComponent {
  @Input() gridApi: GridApi;
  @Input() csvExportParams?: CsvExportParams = {};
  @Input() loadDataHandler?: () => BehaviorSubject<boolean>;
  @Input() context?: any;

  constructor() {
    super();
  }

  exportAsCSV(): void {
    if (!this.loadDataHandler) {
      this._exportAsCSV();
      return;
    }
    const subscription = this.loadDataHandler
      .bind(this.context)()
      .subscribe(isLoaded => {
        if (isLoaded) {
          this._exportAsCSV();
          subscription.unsubscribe();
        }
      });
  }

  private _exportAsCSV(): void {
    this.gridApi.exportDataAsCsv(this.csvExportParams);
  }
}
