<ng-container *ngIf="programInfoTemplate$ | async as templateData">
  <div fxLayout="column" class="grid-parent-div" fxLayoutGap="16px">
    <div class="mat-h1" fxLayout="row" *ngIf="templateData.program?.shortName as programName">
      {{ programName }}
    </div>
    <ng-container *ngIf="templateData.programMembers?.length > 0">
      <hr />
      <div fxLayout="row" fxLayoutAlign="space-between center">
        Participants: {{ templateData.programMembers.length }}
        <app-export-csv-button [gridApi]="gridApi" [csvExportParams]="csvExportParams"> </app-export-csv-button>
      </div>
      <div class="grid-parent-div" fxLayout="column" fxLayoutGap="15px">
        <ag-grid-angular
          [rowData]="templateData.programMembers"
          [gridOptions]="gridOptions"
          class="ag-theme-balham"
          [pagination]="true"
          (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
        <ng-container *ngIf="templateData.numCompleted > 0 && !showRegistrationGrid">
          <div fxLayout="row">
            <span class="horizontal-spacer"></span>
            <button mat-flat-button color="primary" (click)="showRegistrations()">Load Registrations</button>
          </div>
        </ng-container>
        <div class="vertical-spacer"></div>
      </div>
    </ng-container>
    <div
      class="grid-parent-div"
      *ngIf="
        showRegistrationGrid ||
          ((templateData.isProgramInLastOneWeek || true) &&
            templateData.numCompleted >= 0 &&
            templateData.programMembers?.length < 1);
        else noRegistrationView
      "
    >
      <app-program-registrations
        [program]="templateData.program"
        [programId]="programId"
        [numCompleted]="templateData.numCompleted"
        [isProgramInLastOneWeek]="templateData.isProgramInLastOneWeek || true"
      ></app-program-registrations>
    </div>
    <ng-template #noRegistrationView>
      <div
        fxLayout="column"
        fxLayoutGap="10px"
        *ngIf="templateData.isCompleted && templateData.programMembers?.length < 1"
      >
        <div>
          <i>No registrations or participant data was found.</i>
        </div>
        <div>
          <button mat-flat-button color="accent" (click)="showAddRegistrationModal()">
            <span class="icon-fa-plus">
              <i class="fas fa-plus"></i>
            </span>
            Add Registration
          </button>
        </div>
      </div>
    </ng-template>
    <div class="vertical-spacer"></div>
  </div>
</ng-container>
