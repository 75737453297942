import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegistrationNewDto } from '@app/shared/services/registration/registration.entity';
import { RegistrationService } from '@app/shared/services/registration/registration.service';
import { FormGroupDto } from '@app/shared/types/form-group-dto.type';
import { PhoneNumberValidator } from '@app/shared/utils/validators';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-add-registration-dialog',
  templateUrl: './add-registration-dialog.component.html',
  styleUrls: ['./add-registration-dialog.component.scss']
})
export class AddRegistrationDialogComponent {
  registrationFormGroup!: FormGroup<FormGroupDto<RegistrationNewDto>>;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddRegistrationDialogComponent>,
    private registrationService: RegistrationService,
    @Inject(MAT_DIALOG_DATA) public data: { programId: string; checkInEnabled?: boolean },
    private snackBar: MatSnackBar
  ) {
    this.registrationFormGroup = this.fb.group(this.formGroupField);
  }

  private get formGroupField(): FormGroupDto<RegistrationNewDto> {
    return {
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      mobilePhone: new FormControl('', [Validators.required, PhoneNumberValidator()]),
      paymentReference: new FormControl('')
    } as FormGroupDto<RegistrationNewDto>;
  }

  submitRegistration(): void {
    this.registrationService
      .register({
        programId: this.data.programId,
        noRedirect: true,
        suppressEmail: this.data.checkInEnabled,
        adminAccess: true,
        ...this.registrationFormGroup.value
      } as RegistrationNewDto)
      .pipe(
        map(response => {
          if (response.statusCode === 'SUCCESS') {
            this.snackBar.open('Registration Successful', 'Ok');
            this.dialogRef.close();
          } else {
            this.snackBar.open(response.userFriendlyMessage, 'Ok');
          }
        })
      )
      .subscribe();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
