import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponseModel } from '../api-response.model';
import { AppInjector } from '../app-injector';
import { BackendService } from '../backend.service';
import { NotificationService } from '../components/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {
  protected authService: AuthService;
  protected backendService: BackendService;
  protected notificationService: NotificationService;
  constructor() {
    this.authService = AppInjector.injector.get(AuthService);
    this.backendService = AppInjector.injector.get(BackendService);
    this.notificationService = AppInjector.injector.get(NotificationService);
  }

  protected abstract client: string;
  protected abstract feature: string;

  // TODO: to be driven by the dropdown observable
  // protected readonly group = this.authService?.currentGroup;

  endpoint(isAuthEnabled = true): string {
    const prefix = isAuthEnabled ? '_api/' : '/';
    return `${prefix}${this.feature}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected get<T>(endpoint: string, params: any): Observable<ApiResponseModel<T>> {
    const commonParams = {
      client: this.client,
      group: this.authService?.currentGroup
    };
    return this.backendService.get(endpoint, {
      ...params,
      ...commonParams
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected post<T>(endpoint: string, params: any): Observable<ApiResponseModel<T>> {
    const commonParams = {
      client: this.client,
      group: this.authService?.currentGroup
    };
    return this.backendService.post(endpoint, {
      ...params,
      ...commonParams
    });
  }

  protected hasLoadedFromCache<T>(behaviourSubject: BehaviorSubject<ApiResponseModel<T>>, useCache: boolean): boolean {
    const cache = behaviourSubject.getValue();
    const hasCacheValue = Object.keys(cache?.object || {}).length > 0;
    if (useCache && hasCacheValue) {
      behaviourSubject.next(cache);
      return true;
    }

    return false;
  }

  protected clearCache<T>(behaviourSubject: BehaviorSubject<ApiResponseModel<T>>): void {
    behaviourSubject.next({} as ApiResponseModel<T>);
  }

  protected showErrorNotification<T>(result: ApiResponseModel<T>): void {
    if (result.statusCode !== 'SUCCESS') {
      this.notificationService.error(result.userFriendlyMessage);
    }
  }
}
