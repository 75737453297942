import { Injectable } from '@angular/core';
import { List } from '@app/reports/models/list';
import { ApiResponseModel } from '@app/shared/api-response.model';
import { FeatureConstants } from '@app/shared/constants/feature.constants';
import { AppClient } from '@app/shared/constants/global-constant';
import { MailStats } from '@app/shared/entities/mail-stats.entity';
import { BaseService } from '@app/shared/services/base.service';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { DateService } from '../date/date.service';
import { Mail } from './../../entities/mail.entity';

@Injectable({
  providedIn: 'root'
})
export class MailContentService extends BaseService {
  constructor(private dateService: DateService) {
    super();
  }
  client: string = AppClient.isha;
  feature: string = FeatureConstants.Features.mail.name;

  loadingChange$ = new Subject<boolean>();

  private mailContents = new BehaviorSubject<ApiResponseModel<Mail[]>>({} as ApiResponseModel<Mail[]>);
  public mailContents$ = this.mailContents.asObservable();

  private mailStat = new BehaviorSubject<ApiResponseModel<MailStats>>({} as ApiResponseModel<MailStats>);
  public mailStat$ = this.mailStat.asObservable();

  private mailStats = new BehaviorSubject<ApiResponseModel<MailStats>[]>({} as ApiResponseModel<MailStats>[]); 
  public mailStats$ = this.mailStats.asObservable();

  cachedQueryDate = { start: this.dateService.subMonths(1), end: this.dateService.addMonths(1) };

  loadMailTemplates(startDate: string, endDate: string): void {
    this.get<Mail[]>(this.endpoint(), {
      action: FeatureConstants.Features.mail.actions.queryMailContent,
      startYYYYMMDD: this.dateService.formatFromDatePicker(startDate),
      endYYYYMMDD: this.dateService.formatFromDatePicker(endDate)
    }).subscribe(
      mailItem => {
        this.mailContents.next(mailItem);
      },
      error => console.log(`Error: ${error}`)
    );
  }

  loadStats(mailContentIds: number[]) : void {
    const mailStats : Observable<ApiResponseModel<MailStats>>[] = [];
    mailContentIds.forEach(mailContentId => {
      mailStats.push(this.get<MailStats>(this.endpoint(), {
        action: FeatureConstants.Features.mail.actions.viewStats,
        mailContentId
      }));
    });
    forkJoin(mailStats).subscribe(stats => {
        this.mailStats.next(stats);
      }, 
      error => console.log(`Error: ${error}`)
    );
  }

  loadStat(mailContentId: number): void {
    this.loadingChange$.next(true);
    this.get<MailStats>(this.endpoint(), {
      action: FeatureConstants.Features.mail.actions.viewStats,
      mailContentId
    }).subscribe(
      statsItem => {
        this.mailStat.next(statsItem);
        this.loadingChange$.next(false);
      },
      error => console.log(`Error: ${error}`)
    );
  }

  getLists(): Observable<ApiResponseModel<List[]>> {
    return this.get<List[]>(this.endpoint(), {
      action: FeatureConstants.Features.mail.actions.getLists
    });
  }
}
