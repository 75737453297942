import { Program } from '@app/shared/services/program/program.entity';
import { Registration } from '@app/shared/services/registration/registration.entity';
import { ProgInfoMemberDto } from './program-member.dto';

export interface ProgramInfoDto {
  shortName: string;
  startYYYYMMDD: number;
  programId: number;
  fee?: number;
}

export interface RegistrationFormsDialogData {
  registrations: Registration[];
  program: Program;
}

export enum ProgramInfoTemplateConstants {
  "PROGRAM" = "program",
  "PROGRAM_MEMBERS" = "programMembers",
  "NUM_COMPLETED" = "numCompleted",
  "IS_PROGRAM_IN_LAST_ONE_WEEK" = "isProgramInLastOneWeek"
}

export interface ProgramInfoTemplate {
  [ProgramInfoTemplateConstants.PROGRAM]?: Program,
  [ProgramInfoTemplateConstants.PROGRAM_MEMBERS]?: ProgInfoMemberDto[],
  [ProgramInfoTemplateConstants.NUM_COMPLETED]?: number,
  [ProgramInfoTemplateConstants.IS_PROGRAM_IN_LAST_ONE_WEEK]?: boolean,
  isCompleted: boolean
}