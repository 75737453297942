import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Record, Program } from './attendance.entity';
import { PrsBackendService } from '@app/shared/services/prs-backend.service';
import { AuthService } from '@app/auth/auth.service';
import dayjs from 'dayjs';

interface SearchOpts {
  searchStr: string;
  pendingOnly: boolean;
  prefetch: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  constructor(private prs: PrsBackendService, private auth: AuthService) {
  }

  private numCheckins = new BehaviorSubject<number>(0);
  public numCheckins$ = this.numCheckins.asObservable();

  private records = new BehaviorSubject<Record[]>({} as Record[]);
  public records$ = this.records.asObservable();
  public loadingChange$ = this.prs.loadingChange$;

  public registrations: Record[];
  public registrations$ = new BehaviorSubject<Record[]>({} as Record[]);
  public error$ = new BehaviorSubject<string>('');

  private ongoingPrograms = new BehaviorSubject<Program[]>({} as Program[]);
  public ongoingPrograms$ = this.ongoingPrograms.asObservable();

  getPrograms(date: string, key: string = null): void {
    let endpoint: string;

    if (key) {
      endpoint = `attendance/programs?key=${key}`;
    } else {
      const group = this.auth.currentGroup?.toLowerCase().replace(' ', '');
      endpoint = `attendance/programs/${group}?date=${date || ''}`;
    }

    this.prs.get<Program[]>(endpoint, !key)
      .subscribe(pgms => {
          this.ongoingPrograms.next(pgms);
        },
        error => {
          this.error$.next(error);
        }
      );
  }

  loadNumCheckIns(programId: number, sessionId: number, key: string = null): void {
    this.prs.get<number>(`attendance/${programId}/${sessionId}/count?key=${key}`, !key)
      .subscribe(count => {
          this.numCheckins.next(count);
        },
        error => this.error$.next(error)
      );
  }

  get numRegistrations(): number {
    return this.registrations?.length;
  }

  searchMembers(programId: number, searchStr: string, key: string = null): void {
    this.prs.get<Record[]>(`attendance/${programId}/members/${searchStr}?key=${key}`, !key)
      .subscribe(records => {
          this.records.next(this.mapMembers(records));
        },
        error => this.error$.next(error)
      );
  }

  filterRegistrations(searchStr: string, sessionId: number, pendingOnly: boolean): void {
    if (pendingOnly) {
      this.records.next(this.registrations.filter(r => {
        return (searchStr === '' || r.fullText.includes(searchStr)) && !r.checkins[sessionId];
      }));
    } else {
      if (searchStr === '') {
        this.clearRecords();
      } else {
        this.records.next(this.registrations.filter(r => r.fullText.includes(searchStr)));
      }
    }
  }

  clearRegistrations(): void {
    this.registrations = [];
  }

  searchRegistrations(programId: number, sessionId: number, opts: SearchOpts, key: string = null): void {
    if (!this.numRegistrations || !opts.searchStr || opts.prefetch) {
      this.prs.get<Record[]>(`attendance/${programId}/registrations?key=${key}`, !key)
        .subscribe(regs => {
            this.registrations = this.mapRegistrations(sessionId, regs);
            this.registrations$.next(this.registrations);
            this.filterRegistrations(opts.searchStr, sessionId, opts.pendingOnly);
          },
          error => this.error$.next(error)
        );
    } else {
      this.filterRegistrations(opts.searchStr, sessionId, opts.pendingOnly);
    }
  }

  mapRegistrations(sessionId: number, regs: Record[]): Record[] {
    return regs.map(r => ({
      ...r,
      fullText: (r.firstName + r.lastName + r.email + r.phoneNumber).toLowerCase(),
      error: sessionId > 0 && !r.checkins[sessionId - 1] && `Session ${sessionId} not checked in`
    }));
  }

  mapMembers(members: Record[]): Record[] {
    return members.map(m => ({
      ...m,
      error: !m.meditator && 'Not a meditator'
    }));
  }

  clearRecords(refetch = false): void {
    this.records.next([]);
    if (refetch) {
      this.clearRegistrations();
    }
  }

  checkin(pgm: Program, sessionId: number, member: Record, key: string = null): void {
    this.prs.post(`attendance/${pgm.id}/member/${member.memberId}/${sessionId}?key=${key}`, null, !key)
      .subscribe(() => {
          this.loadNumCheckIns(pgm.id, sessionId, key);
          this.registrations.find(r => r.memberId === member.memberId).checkins[sessionId] = dayjs().toISOString();
        },
        error => this.error$.next(error)
      );
  }

}
