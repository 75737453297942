import { AgGridEvent, FirstDataRenderedEvent, GridBodyCtrl } from 'ag-grid-community';

export function onFirstDataRendered<T>(params: FirstDataRenderedEvent<T>): void {
  fixColumnWidths(params);
}

export function fixColumnWidths<T>(params: AgGridEvent<T>): void {
  // params.api.sizeColumnsToFit();
  params.columnApi.autoSizeAllColumns();

  // tslint:disable-next-line: no-string-literal
  const gridBodyCtrl = params.api?.['gridBodyCtrl'] as GridBodyCtrl;
  const availableWidth = gridBodyCtrl.getBodyViewportElement().clientWidth;
  // const availableWidth = agGridElement.nativeElement.clientWidth;
  // const availableWidth = window.innerWidth;
  const columns = params.columnApi.getAllDisplayedColumns();
  // tslint:disable-next-line: no-string-literal
  const usedWidth = columns.reduce((acc, curr) => (acc += curr.getActualWidth()), 0);
  if (usedWidth < availableWidth) {
    params.api.sizeColumnsToFit();
  } else {
    params.columnApi.autoSizeAllColumns();
  }
}
