import { Subject } from 'rxjs';
import { AppInjector } from '../app-injector';
import { BackendService } from '../backend.service';

export class BaseComponent {
  protected backendService: BackendService;

  isSubmitting$: Subject<boolean>;
  constructor() {
    this.backendService = AppInjector.injector.get(BackendService);
    this.isSubmitting$ = this.backendService?.loadingChange$;
  }
}
