import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DefaultColumnDefFilterParam, DefaultGridOptions } from '@app/shared/constants/global-constant';
import { ColumnDef } from '@app/shared/entities/column-def.entity';
import { GridOptions } from '@app/shared/entities/grid-options.entity';
import { DateConstants } from '@app/shared/services/date/date-constants';
import { DateService } from '@app/shared/services/date/date.service';
import { VolunteerReportService } from '@app/shared/services/vols/volunteer-report.service';
import { VolunteerEntity } from '@app/shared/services/vols/volunteer.entity';
import { map } from 'rxjs/operators';
import { SiaVolsDto } from './sia-vols.dto';

@Component({
  selector: 'app-sia-vols',
  templateUrl: './sia-vols.component.html',
  styleUrls: ['./sia-vols.component.scss']
})
export class SiaVolsComponent implements OnInit {
  constructor(
    private volunteerReportService: VolunteerReportService,
    private route: ActivatedRoute,
    private dateService: DateService
  ) {}

  rowData$ = this.volunteerReportService.siaVolsReport$.pipe(map(a => a?.object?.map(this.mapToDto)));
  gridOptions: GridOptions<SiaVolsDto>;

  columnDefs: Array<ColumnDef<SiaVolsDto>> = [
    { headerName: 'First Name', field: 'firstName', width: 150, minWidth: 150 },
    { headerName: 'Last Name', field: 'lastName', width: 150, minWidth: 150 },
    { headerName: 'Gender', field: 'gender', width: 50, minWidth: 50 },
    { headerName: 'Email', field: 'email', width: 200, minWidth: 200 },
    { headerName: 'Mobile Phone', field: 'mobilePhone', width: 100, minWidth: 100 },
    { headerName: 'Occupation', field: 'occupation', width: 150, minWidth: 100 },
    { headerName: 'Company', field: 'company', width: 100, minWidth: 100 },
    { headerName: 'City', field: 'city', width: 100, minWidth: 100 },
    { headerName: 'State', field: 'state', width: 100, minWidth: 100 },
    { headerName: 'Country', field: 'country', width: 100, minWidth: 100 },
    { headerName: 'Pincode', field: 'pincode', width: 100, minWidth: 100 },
    { headerName: 'Availability', field: 'volunteeringAvailability', width: 150, minWidth: 100 },
    { headerName: 'Paid Amt', field: 'amount', width: 150, minWidth: 100 },
    {
      headerName: 'Paid Date',
      field: 'paidOn',
      width: 150,
      minWidth: 100,
      type: 'date',
      filterParams: DefaultColumnDefFilterParam,
      filter: 'date',
      valueFormatter: (params: any): string =>
        params.value
          ? this.dateService.parseFormatDateString(
              params.value,
              DateConstants.ClientFormat,
              DateConstants.ProgramDateFormat
            )
          : ''
    }
  ];

  ngOnInit(): void {
    const programId = this.route.snapshot.params.programId;
    this.volunteerReportService.loadVolunteerReport(programId);
    this.gridOptions = { ...DefaultGridOptions, columnDefs: this.columnDefs };
  }

  mapToDto(entity: VolunteerEntity): SiaVolsDto {
    return {
      firstName: entity.firstName,
      lastName: entity.lastName,
      gender: entity.gender,
      email: entity.email,
      mobilePhone: entity.mobilePhone,
      occupation: entity.occupation,
      company: entity.company,
      city: entity.homeAddress.city,
      state: entity.homeAddress.state,
      country: entity.homeAddress.country,
      pincode: entity.homeAddress.pincode,
      volunteeringAvailability: entity.options.volunteeringAvailability,
      amount: entity.amount,
      paidOn: entity.paidOn
    };
  }
}
