<mat-dialog-content>
  <div fxLayout="column">
    <div style="position: relative">
      <h3 class="text-center mat-headline">Registration Forms</h3>
      <button
        style="position: absolute; top: 0; right: 0"
        mat-icon-button
        type="button"
        aria-hidden="true"
        (click)="cancel()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-raised-button color="primary" printSectionId="regForms" ngxPrint>
        <mat-icon>print</mat-icon> Print
      </button>
    </div>
  </div>
  <hr />
  <div class="modal-body" id="regForms">
    <div *ngFor="let reg of data?.registrations">
      <div class="form-container">
        <div fxLayout="row">
          <div fxFlex="10"><img class="img-responsive" src="/assets/isha-logo.jpg" /></div>
          <div fxFlex class="mat-headline" fxLayout="row" fxLayoutAlign="end center">
            <span><strong>Registration Form</strong></span>
          </div>
        </div>
      </div>
      <hr />
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="heading">{{ data?.program?.programTypeProp?.displayName }}</div>
        <div class="subheading">
          {{ data?.program?.startYYYYMMDD + 'T000000Z' | date: 'EEE, MMM d' }}
          <span *ngIf="data?.program?.endYYYYMMDD && data?.program?.startYYYYMMDD != data?.program?.endYYYYMMDD">
            - {{ data?.program?.endYYYYMMDD + 'T000000Z' | date: 'EEE, MMM d' }}</span
          >
          @ {{ data?.program?.venueProp?.displayName }}
        </div>
        <div class="subheading" style="margin-top: 20px"><u>Please verify/fill the details below</u></div>
      </div>
      <div class="form-container">
        <h4 class="mat-h3"><strong>Contact Details</strong></h4>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="50">
            <div class="field-value">{{ reg.firstName || ' ' }}</div>
            <div class="field-label">First Name</div>
          </div>
          <div fxFlex="50">
            <div class="field-value">{{ reg.lastName || ' ' }}</div>
            <div class="field-label">Last Name</div>
          </div>
        </div>
        <div fxLayout="row">
          <div class="col-12" style="width: 100%">
            <div class="field-value">{{ reg.email || ' ' }}</div>
            <div class="field-label">Email Address</div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div class="col-4" fxFlex="33">
            <div class="field-value">{{ reg.mobilePhone || ' ' }}</div>
            <div class="field-label">Phone Number</div>
          </div>
          <div class="col-4" fxFlex="33">
            <div class="field-value">{{ reg?.customFields?.age || ' ' }}</div>
            <div class="field-label">Age</div>
          </div>
          <div class="col-4" fxFlex="33">
            <div class="field-value">{{ reg.gender || ' ' }}</div>
            <div class="field-label">Gender</div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div class="col-4" fxFlex="33">
            <div class="field-value">
              {{
                reg.city && reg.state ? reg.city + ' / ' + reg.state : reg.city ? reg.city : reg.state ? reg.state : ''
              }}
            </div>
            <div class="field-label">City / State</div>
          </div>
          <div class="col-4" fxFlex="33">
            <div class="field-value">{{ reg.country || ' ' }}</div>
            <div class="field-label">Country</div>
          </div>
          <div class="col-4" fxFlex="33">
            <div class="field-value">{{ reg.postalCode || ' ' }}</div>
            <div class="field-label">Postal Code</div>
          </div>
        </div>
        <hr />
        <div *ngIf="data?.program?.show?.detailedPrevPrograms">
          <h4 class="heading">Details of Programs attended so far</h4>
          <div class="col-12" *ngIf="data?.program?.show?.detailedPrevPrograms?.ie">
            <div class="field-value">{{ reg.customFields.ie || ' ' }}</div>
            <div class="field-label">Inner Engineering (7 Day / 4 Day / 2 Day)</div>
          </div>
          <div class="col-12" *ngIf="data?.program?.show?.detailedPrevPrograms?.bsp">
            <div class="field-value">{{ reg.customFields.bsp || ' ' }}</div>
            <div class="field-label">Bhava Spandana Program</div>
          </div>
          <div class="col-12" *ngIf="data?.program?.show?.detailedPrevPrograms?.hy">
            <div class="field-value">{{ reg.customFields.hy || ' ' }}</div>
            <div class="field-label">Hatha Yoga Programs</div>
          </div>
          <div class="col-12" *ngIf="data?.program?.show?.detailedPrevPrograms?.shoonya">
            <div class="field-value">{{ reg.customFields.prevShoonya || ' ' }}</div>
            <div class="field-label">Shoonya</div>
          </div>
          <div class="col-12" *ngIf="data?.program?.show?.detailedPrevPrograms?.other">
            <div class="field-value">{{ reg.customFields.otherPrograms || ' ' }}</div>
            <div class="field-label">Other Programs</div>
          </div>
        </div>
        <div *ngIf="data?.program?.show?.ishaPrograms">
          <h4 class="heading">Other Isha Programs</h4>
          <h5 class="sub-heading"><i>Have you attended any of the below Isha program before?</i></h5>
          <div class="col-12">
            <div class="field-value">{{ reg.customFields.ishaPrograms || ' ' }}</div>
            <div class="field-label">Other Isha Programs</div>
          </div>
        </div>
        <div *ngIf="data?.program?.show?.otherPrograms">
          <h4 class="heading">Any other program you have attended</h4>
          <h5 class="sub-heading"><i>On Yoga, Meditation, Personality Development, etc.</i></h5>
          <div class="col-12">
            <div class="field-value">{{ reg.customFields.otherPrograms || ' ' }}</div>
            <div class="field-label">Other Programs</div>
          </div>
        </div>
        <div *ngIf="data?.program?.show?.healthConditions">
          <hr />
          <div>
            <h4 class="heading">Details on Health Conditions</h4>
            <h5 class="sub-heading">
              <i
                >Please provide the details to below questions accurately and completely so that we can support you as
                needed for this program. It will be kept confidential and will only be used to support you during and
                after the program.</i
              >
            </h5>
            <h4 class="heading">Physical ailments</h4>
            <h5 class="no-margin-bottom">
              <i *ngIf="!data?.program?.descriptions.physicalAilment"
                >Do you have or have you had any physical ailments? If yes, provide details.</i
              ><i
                *ngIf="data?.program?.descriptions.physicalAilment"
                ng-bind-html="program?.descriptions.physicalAilment"
              ></i>
            </h5>
            <div class="col-12">
              <div class="field-value">{{ reg.customFields.physicalAilments || ' ' }}</div>
              <div class="field-label"></div>
            </div>
          </div>
          <div *ngIf="data?.program?.show?.pregnancy">
            <h4 class="heading">Pregnancy</h4>
            <h5 class="sub-heading"><i>Are you currently pregnant? (NA for Gents)</i></h5>
            <div class="col-12">
              <div class="field-value">{{ reg.customFields.pregnancy || ' ' }}</div>
              <div class="field-label"></div>
            </div>
          </div>
          <div>
            <h4 class="heading">Mental ailments</h4>
            <h5 class="no-margin-bottom">
              <i *ngIf="!data?.program?.descriptions.mentalAilment"
                >Do you have or have you had any mental ailments? If yes, provide details.</i
              ><i
                *ngIf="data?.program?.descriptions.mentalAilment"
                [innerHtml]="data?.program?.descriptions.mentalAilment"
              ></i>
            </h5>
            <div class="col-12">
              <div class="field-value">{{ reg.customFields.mentalAilments || ' ' }}</div>
              <div class="field-label"></div>
            </div>
          </div>
        </div>
        <div>
          <div *ngIf="data?.program?.tAndC?.length &gt; 0">
            <h5><strong>Terms & Conditions</strong></h5>
            <ul>
              <li *ngFor="let tc of data?.program?.tAndC">
                <small>{{ tc }}.</small>
              </li>
            </ul>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <h4 class="text-center">I agree to the Terms & Conditions</h4>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" class="form-row sign-row">
          <div fxFlex="50">
            <div class="field-value">{{ reg.date || ' ' }}</div>
            <div class="field-label">Date</div>
          </div>
          <div fxFlex="50">
            <div class="field-value">{{ reg.signature || ' ' }}</div>
            <div class="field-label" id="signature">Signature</div>
          </div>
        </div>
      </div>
      <div class="page-break"></div>
    </div>
  </div>
</mat-dialog-content>
