import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface HyperlinkCellRendererParams<T> extends ICellRendererParams {
  text: string;
  textProperty: keyof T;
  hrefSimple: string;
  hrefProperty: keyof T;
  css: string[];
  target: Target;
  hrefDynamic(data: T): string;
  textDynamic(data: T): string;
}

export enum Target {
  blank = '_blank',
  self = '_self',
  parent = '_parent',
  top = '_top'
}

@Component({
  selector: 'app-hyperlink-cell-renderer',
  template: `<a [href]="href" [target]="target" [class]="css">{{ hrefText }}</a> `
})
export class HyperlinkCellRendererComponent<T> implements ICellRendererAngularComp {
  public params: HyperlinkCellRendererParams<T>;

  agInit(params: HyperlinkCellRendererParams<T>): void {
    this.params = params;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(params: HyperlinkCellRendererParams<T>): boolean {
    return false;
  }

  get target(): Target {
    return this.params?.target || Target.blank;
  }

  get hrefText(): string {
    return this?.params?.data?.[this.params?.textProperty] || this?.params?.text || this.textDynamic;
  }

  get href(): string {
    return this.params?.data?.[this.params?.hrefProperty] || this.params?.hrefSimple || this.hrefDynamic;
  }

  get css(): string[] {
    return this.params?.css;
  }

  get hrefDynamic(): string {
    return this.params?.hrefDynamic?.(this.params?.data);
  }

  get textDynamic(): string {
    return this.params?.textDynamic?.(this.params?.data);
  }
}
