<div fxFlex="100%" fxLayout fxLayoutGap="2px">
  <div fxFlex="grow">
    <div [ngClass]="data.type" [innerHTML]="data.msg"></div>
  </div>
  <div fxFlex="auto" fxLayout fxLayoutAlign="end">
    <button mat-icon-button color="accent" (click)="snackbarRef.dismiss()" fxLayoutAlign="end start">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
