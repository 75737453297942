import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { NotificationService } from '@app/shared/components/notification/notification.service';
import { map, tap } from 'rxjs/operators';
import { PermissionService } from './permission.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private permissionService: PermissionService,
    private notificationService: NotificationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.checkAuth();
  }

  sendNotification(hasPermissions: boolean): void {
    if (!hasPermissions) {
      this.notificationService.info(
        'You have been redirected to home page because you do not have access to the requested page'
      );
      this.router.navigateByUrl('');
    }
  }
}
