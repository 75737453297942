import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from '@app/shared/backend.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentGroupService } from './current-group.service';
import { LoginUser } from './user.model';
import { PrsBackendService } from '@app/shared/services/prs-backend.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private router: Router,
    private backend: BackendService,
    private prs: PrsBackendService,
    private currentGroupService: CurrentGroupService
  ) {
    this.initGroup();
  }
  user$ = new BehaviorSubject<LoginUser | null>(null);
  currentGroup$ = new BehaviorSubject<string>('');
  currentSelectedGroup$ = this.currentGroup$.asObservable();

  checkAuth(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      if (this.getUser()) {
        return observer.next(true);
      }

      this.prs.get<LoginUser>('login').subscribe(
        user => {
          this.user$.next(user);
          observer.next(true);
        },
        error => {
          this.logout();
          observer.error();
          console.log(`Error: ${error}`);
        }
      );
    });
  }

  logout(): void {
    this.user$.next(null);
    this.backend.api('portal.logout', {}).subscribe(
      () => {
        this.router.navigate(['/login']);
      },
      () => {
        this.router.navigate(['/login']);
      }
    );
  }

  getUser(): LoginUser | null {
    return this.user$.value;
  }

  setGroup(group: string): void {
    if (!group) { return; }
    this.user$.subscribe(user => this.currentGroupService.setCurrentGroup(user, group));
    this.currentGroup$.next(group);
  }

  private initGroup(): void {
    this.user$.subscribe(user => {
      const groupName = this.currentGroupService.getCurrentGroup(user);
      this.currentGroup$.next(groupName);
    });
  }

  get currentGroup(): string {
    return this.currentGroup$.value;
  }

  hasPermission(permission: string): boolean {
    return this.getUser().permissions[this.currentGroup][permission] || false;
  }
}
