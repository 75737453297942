import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from './notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private snackbar: MatSnackBar) {}

  success(msg: string, autoHide?: boolean): void {
    this.show(msg, 'success', autoHide ? 3000 : -1);
  }

  info(msg: string, autoHide?: boolean): void {
    this.show(msg, 'info', autoHide ? 3000 : -1);
  }

  error(msg: string, autoHide?: boolean): void {
    this.show(msg, 'error', autoHide ? 3000 : -1);
  }

  show(msg: string, type: string, duration: number): void {
    this.snackbar.openFromComponent(NotificationComponent, {
      data: { msg, type },
      panelClass: type,
      duration,
      verticalPosition: 'top'
    });
  }

  hide(): void {
    this.snackbar.dismiss();
  }
}
