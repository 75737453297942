import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { JsonEditorSchemaService } from '../json-editor/json-editor-schema.component';
import { JsonEditorListData } from './json-editor-list.data';

@Component({
  selector: 'app-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class JsoneditorComponent {
  public editorOptions: JsonEditorOptions;

  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

  constructor(
    public dialogRef: MatDialogRef<JsoneditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JsonEditorListData,
    public dialog: MatDialog,
    private jsonEditorSchemaService: JsonEditorSchemaService
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.mode = 'tree';
    this.editorOptions.schema = this.jsonEditorSchemaService.getSchema();
  }

  saveJson() {
    let str = JSON.stringify(this.editor.get())
    this.dialogRef.close(str);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
