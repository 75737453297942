import { GridOptions } from '../entities/grid-options.entity';
import { onFirstDataRendered } from '../utils/ag-grid/ag-grid-util';

export enum AppClient {
  isha = 'Isha'
}

export enum AppFeature {
  campaign = 'campaign',
  group = 'group',
  member = 'member',
  program = 'program',
  attendance = 'attendance'
}

export const DefaultGridOptions: Partial<GridOptions<any>> = {
  defaultColDef: {
    checkboxSelection: false,
    resizable: true,
    sortable: true,
    filter: true,
    floatingFilter: true,
    suppressMovable: true
  },
  columnTypes: {
    date: {
      floatingFilter: true,
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',

      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue: string | number) => {
          let cellDate;

          if (typeof cellValue === 'number') {
            cellDate = new Date(cellValue);
          } else if (cellValue.length === 6) {
            const year = Number(cellValue.substring(0, 3));
            const month = Number(cellValue.substring(4, 5));
            const day = Number(cellValue.substring(6, 7));
            cellDate = new Date(year, month, day);
          } else {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue && cellValue.split('-');
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            cellDate = new Date(year, month, day);
          }

          // Now that both parameters are Date objects, we can compare
          if (cellDate?.setHours(0, 0, 0, 0) < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate.setHours(0, 0, 0, 0) > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    }
  },
  onFirstDataRendered,
  suppressCellFocus: true, // Disables cell range selection in the grid, preventing cells from being highlighted when clicked
  enableCellTextSelection: true, // Allows users to select and copy cell text using the cursor, similar to selecting text in a regular input field
  ensureDomOrder: true // Ensures that the rendered ag-Grid cells maintain their DOM order, which can be important for accessibility and screen reader compatibility
};

export const DefaultColumnDefFilterParam = { buttons: ['reset'] };

export const DATE_PICKER_APPLY_LABEL = ' Ok';

export const Currencies = ['SGD', 'USD', 'INR', 'MYR', 'AUD', 'GBP', 'THB'];

export const RegExPatterns = {
  digitsOnly: '[0-9]+'
};
