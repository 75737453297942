import { Injectable } from '@angular/core';
import { SubscriptionParams } from '@app/profile/models/subscription';
import { Params } from '@app/reports/models/params';
import { ApiResponseModel } from '@app/shared/api-response.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { FeatureConstants } from '../../constants/feature.constants';
import { AppClient } from '../../constants/global-constant';
import { BaseService } from '../base.service';
import { Member, UpdateMember } from './member.entity';
import { UnverifiedProgram } from './unverified-program.entity';
import { VerifiedProgram } from './verified-program.entity';

@Injectable({
  providedIn: 'root'
})
export class MemberService extends BaseService {
  constructor() {
    super();
  }

  client = AppClient.isha;
  feature = FeatureConstants.Features.member.name;

  private memberDetails = new BehaviorSubject<ApiResponseModel<Member[]>>({} as ApiResponseModel<Member[]>);
  public memberDetails$ = this.memberDetails.asObservable();

  private contactDetails = new BehaviorSubject<ApiResponseModel<Member>>({} as ApiResponseModel<Member>);
  public contactDetails$ = this.contactDetails.asObservable();

  loadContact(memberId: string | null): void {
    this.get<Member>(this.endpoint(true), {
      action: FeatureConstants.Features.member.actions.detailedInfo,
      memberId
    }).subscribe(
      member => {
        this.contactDetails.next(member);
      },
      error => console.log(`Error: ${error}`)
    );
  }

  createContact(profileData: UpdateMember): Observable<ApiResponseModel<Member>> {
    const { homeAddress, officeAddress } = profileData;
    const home = this.parseAddress(homeAddress, 'homeAddress');
    const office = this.parseAddress(officeAddress, 'officeAddress');

    return this.post<Member>(this.endpoint(true), {
      action: FeatureConstants.Features.member.actions.create,
      ...profileData,
      ...home,
      ...office
    });
  }

  updateContact(memberId: string | null, profileData: UpdateMember): Observable<ApiResponseModel<Member>> {
    const { homeAddress, officeAddress } = profileData;
    const home = this.parseAddress(homeAddress, 'homeAddress');
    const office = this.parseAddress(officeAddress, 'officeAddress');

    return this.post<Member>(this.endpoint(true), {
      action: FeatureConstants.Features.member.actions.updateContactDetails,
      ...profileData,
      ...home,
      ...office,
      memberId
    });
  }

  search(searchStr: string): Observable<ApiResponseModel<Member[]>> {
    return this.get<Member[]>(this.endpoint(), {
      action: FeatureConstants.Features.member.actions.quickSearch,
      searchStr
    });
  }

  query(params: Params): Observable<ApiResponseModel<any>> {
    return this.get<any>(this.endpoint(), {
      action: FeatureConstants.Features.member.actions.query,
      ...params
    });
  }

  loadMemberDetails(programId: string): void {
    this.get<Member[]>(this.endpoint(), {
      action: FeatureConstants.Features.member.actions.query,
      programId,
      allGroups: true
    }).subscribe(
      member => {
        this.memberDetails.next(member);
      },
      error => console.log(`Error: ${error}`)
    );
  }

  saveUnverifiedProgram(unverifiedProgram: UnverifiedProgram): Observable<ApiResponseModel<Member>> {
    return this.post<Member>(this.endpoint(true), {
      action: FeatureConstants.Features.member.actions.addUnverifiedProgram,
      ...unverifiedProgram
    });
  }

  saveVerifiedProgram(verifiedProgram: VerifiedProgram): Observable<ApiResponseModel<Member>> {
    return this.post<Member>(this.endpoint(true), {
      action: FeatureConstants.Features.member.actions.addVerifiedProgram,
      ...verifiedProgram
    });
  }

  updateSubscription(params: SubscriptionParams): Observable<ApiResponseModel<Member>> {
    return this.post<Member>(this.endpoint(true), {
      action: FeatureConstants.Features.member.actions.updateGroupSubscription,
      ...params
    });
  }

  parseAddress(address: { [k: string]: string }, name: string) {
    let output = {};
    Object.keys(address).forEach(key => {
      output[`${name}.${key}`] = address[key];
    });
    return output;
  }
}
