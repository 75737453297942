<!--suppress HtmlFormInputWithoutLabel -->
<section>
  <div fxFlex="20%" fxHide.lt-sm></div>
  <div fxFlex="grow" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <h1 translate>loading</h1>
    </div>
  </div>
  <div fxFlex="20%" fxHide.lt-sm></div>
</section>
