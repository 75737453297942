export class ArrayUtil {
  public static isInArray(item: string, items: string[]): boolean {
    return items?.some(a => a === item);
  }

  public static hasCommonItems(items1: string[], items2: string[]): boolean {
    return items1?.some(a => this.isInArray(a, items2));
  }

  public static sort<T>(a: any, b: any, prop: keyof T, order: 'asc' | 'desc' = 'asc') {
    return order === 'asc' ? a[prop] - b[prop] : b[prop] - a[prop];
  }
}
