import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ExportCsvButtonComponent } from './components/export-csv-button/export-csv-button.component';
import { JsoneditorComponent } from './components/json-editor/json-editor.component';
import { PermissionsDirective } from './directives/permissions/permissions.directive';

@NgModule({
  declarations: [PermissionsDirective, ExportCsvButtonComponent, JsoneditorComponent],
  imports: [CommonModule, MaterialModule, FontAwesomeModule, NgJsonEditorModule],
  exports: [PermissionsDirective, ExportCsvButtonComponent, JsoneditorComponent]
})
export class SharedModule {}
