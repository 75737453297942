import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
// import { NgxPrintModule } from 'ngx-print';
import { AddRegistrationDialogComponent } from './components/add-registration-dialog/add-registration-dialog.component';
import { AddRegistrationFormComponent } from './components/add-registration-form/add-registration-form.component';
import { CancelledRegistrationsComponent } from './components/cancelled-registrations/cancelled-registrations.component';
import { PendingRegistrationsComponent } from './components/pending-registrations/pending-registrations.component';
import { PrintFormsButtonComponent } from './components/print-forms-button/print-forms-button.component';
import { RegistrationFormsDialogComponent } from './components/registration-forms-dialog/registration-forms-dialog.component';
import { HealthInfoComponent } from './health-info/health-info.component';
import { ProgramInfoRoutingModule } from './program-info-routing.module';
import { ProgramInfoComponent } from './program-info.component';
import { ProgramRegistrationComponent } from './program-registrations/program-registrations.component';

@NgModule({
  declarations: [
    ProgramInfoComponent,
    AddRegistrationFormComponent,
    AddRegistrationDialogComponent,
    PrintFormsButtonComponent,
    ProgramRegistrationComponent,
    RegistrationFormsDialogComponent,
    CancelledRegistrationsComponent,
    PendingRegistrationsComponent,
    HealthInfoComponent
  ],
  imports: [
    CommonModule,
    AgGridModule,
    ProgramInfoRoutingModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    // NgxPrintModule,
    SharedModule
  ]
})
export class ProgramInfoModule {}
