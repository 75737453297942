import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgramInfoComponent } from './program-info.component';
import { SiaVolsComponent } from './sia-vols/sia-vols.component';
import { HealthInfoComponent } from '@app/program-info/health-info/health-info.component';

const routes: Routes = [
  { path: 'info', component: ProgramInfoComponent },
  { path: 'health', component: HealthInfoComponent },
  { path: 'volunteers', component: SiaVolsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramInfoRoutingModule {}
