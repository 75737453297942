import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(regionCode?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: string } | null => {
    let validNumber = false;
    let err = null;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      if (!validNumber) err = 'invalid number';
    } catch (e) {
      err = (e as string).toString().substring(7);
      if (err === 'The string supplied did not seem to be a phone number') err = 'invalid number';
    }

    return validNumber ? null : { wrongNumber: err };
  };
}
