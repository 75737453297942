import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProgramInfoDto } from '@app/program-info/program-info.dto';
import { ProgInfoMemberDto } from '@app/program-info/program-member.dto';
import { Program } from '@app/shared/services/program/program.entity';
import { Registration } from '@app/shared/services/registration/registration.entity';
import { RegistrationFormsDialogComponent } from '../registration-forms-dialog/registration-forms-dialog.component';

@Component({
  selector: 'app-print-forms-button',
  templateUrl: './print-forms-button.component.html',
  styleUrls: ['./print-forms-button.component.scss']
})
export class PrintFormsButtonComponent implements OnInit {
  @Input() registrations: Registration[];
  @Input() program: Program;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  showRegFormsModal() {
    const dialogRef = this.dialog.open(RegistrationFormsDialogComponent, {
      width: '60%',
      height: '90vh',
      data: {
        registrations: this.registrations,
        program: this.program
      }
    });
  }
}
