import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '@app/auth/permission.service';

@Directive({
  selector: '[appPermissions]'
})
export class PermissionsDirective {
  constructor(
    private permissionService: PermissionService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input()
  set appPermissions(permissions: string[]) {
    this.createView(permissions);
  }

  createView(permissions: string[]): void {
  }
}
